html,
#root,
.App {
  height: 100%;
  font-family: "Rubik", sans-serif;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
  background: #2d2a2f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
  white-space: pre;
}

button {
  border: none;
  font: inherit;
  background: inherit;
}

.topBar {
  display: flex;
  color: #ccc;
  padding: 0 0 12px;
}

.menuButton {
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: #ccc;
  font-family: "Rubik";
}

.menuButton:hover:not(.open) {
  background: #49424d;
  color: #fff;
}

.menuButton.open {
  color: #fff;
}

.dropdownItem input[type="file"] {
  display: none;
}

.editorContainer {
  display: flex;
  flex-grow: 1;
  height: 0;
}

.left {
  flex-grow: 1;
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  background: #373f41;
  display: flex;
  flex-direction: column;
}

.left.hasFocus {
  background: #414b4d;
}

.header {
  display: flex;
}

.headerButton {
  padding: 12px 16px;
  border: none;
  font-family: "Rubik";
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.left .header {
  background: #31383a;
}

.left .headerButton {
  color: #ccc;
  background: #2e3637;
  border: 1px solid #414b4d;
  border-width: 0 1px;
}

.left .headerButton:first-child {
  border-left-width: 0;
}

.left .headerButton:last-child {
  border-right-width: 0;
}

.left .headerButton:hover {
  background: #343c3e;
}

.left .headerButton.active {
  color: #fff;
  background: #414b4d;
}

.right .header {
  background: #36354b;
}

.right .headerButton {
  background: #42405c;
}

.right .headerButton:hover {
  background: #484666;
}

.right .headerButton.active {
  background: #4f4d6f;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #495557;
  min-width: 500px;
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  height: 100%;
}

.code {
  padding: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

.macros {
  overflow: auto;
}

.macroOuter {
  display: flex;
  flex-direction: column;
  border-top: 4px solid rgba(255, 255, 255, 0.1);
}

.macroOuter.hasFocus {
  border-top-color: #f77825;
  background: #505d60;
}

.macroHeader {
  display: flex;
  align-items: center;
  padding: 12px 12px 0;
}

.inline {
  color: #ccc;
}

.macroTitle {
  color: #fff;
  background: #5f7174;
  font-size: 15px;
  text-align: left;
  padding: 8px 12px;
  display: flex;
  align-self: flex-start;
  border-radius: 5px;
}

.macroTitle.hasFocus {
  background: rgba(0, 240, 210, 0.4);
}

.line {
  display: flex;
  padding: 2px 0;
  position: relative;
}

.line.selected:after {
  content: " ";
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100%);
  left: -4px;
  top: 0px;
  background: rgba(0, 240, 210, 0.4);
}

.line.selected.highlight:after {
  background: rgba(0, 240, 210, 0.6);
}

.lineNumber {
  color: #aaa;
  margin-right: 16px;
  user-select: none;
  width: 30px;
  text-align: left;
}

.instruction {
  display: flex;
  align-items: center;
}

.instruction div {
  position: relative;
}

.indent {
  position: relative;
  height: 100%;
  width: 30px;
}

.indent:after {
  content: " ";
  position: absolute;
  left: 3px;
  top: -2px;
  width: 2px;
  /* top: -15px; */
  height: calc(100% + 4px);
  border-right: 2px solid #307580;
}

.indent.compare:after {
  width: 12px;
  height: 50%;
  top: 2px;
  left: 8px;
  border: none;
  border-bottom: 2px solid #308054;
  border-left: 2px solid #308054;
  border-bottom-left-radius: 2px;
}

.fragment.highlight:after {
  content: " ";
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  left: -4px;
  top: -4px;
  border-radius: 2px;
  background: rgba(0, 170, 255, 0.4);
}

.fragment.missing,
.fragment.placeholder {
  margin: 0 2px;
}

.fragment:not(.highlight).placeholder:after,
.fragment:not(.highlight).missing:after {
  content: " ";
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 4px);
  left: -4px;
  top: -2px;
  border-radius: 2px;
  background: rgba(165, 62, 210, 0.4);
}

.fragment.macroName {
  color: #e976d8;
}

.macro-param-label {
  color: #aaa;
  display: inline;
}

.macro-paren {
  color: #999;
  display: inline;
  margin-top: -2px;
}

.macro-paren.open {
  margin-right: 4px;
}

.macro-paren.close {
  margin-left: 4px;
}

.macro-block-braces {
  display: flex;
  margin-left: 46px;
  color: #aaa;
}

.fragment {
  display: flex;
  align-items: center;
}

.fragment.scope {
  color: #00dffc;
}

.fragment.def {
  color: #fe4365;
}

.fragment.assign {
  color: #cfa4f5;
}

.fragment.compare {
  color: #7cdab9;
}

.fragment.assignAction {
  color: #f77825;
}

.fragment.comparator {
  color: #f9cdad;
}

.fragment.os {
  color: #fc9d9a;
}

.fragment.jump {
  color: #fae571;
}

.empty {
  content: "\200b";
  height: 19px;
  min-width: 30px;
  color: rgba(255, 255, 255, 0.5);
}

.bold-hint {
  color: rgba(255, 255, 255, 0.7);
}

.right .buttons {
  align-items: flex-start;
  width: 100%;
  display: flex;
  background: #3c3b54;
}

.right .buttons button {
  border: none;
  background: #484665;
  height: 44px;
  width: 80px;
  color: #fff;
  font-weight: 600;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
  font-family: "Rubik";
}

.right .buttons button:hover {
  background: #444;
}

.buildContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
}

.outputs {
  color: #fff;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
  height: 100%;
  flex-grow: 1;
}

.outputLine {
  display: flex;
}

.outputLine .lineNumber {
  width: auto;
}

.assemblyContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header.subheader {
  background: #4f4d6f;
}

.subheaderButton {
  padding: 12px 16px;
  border: none;
  font-family: "Rubik";
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background: #4f4d6f;
}
.subheaderButton:hover {
  background: #67658b;
}

.subheaderButton.active {
  background: #6d6b94;
}

.divider {
  flex-grow: 1;
}

.asmInner {
  font-family: "Source Code Pro";
  font-weight: 600;
  width: 500px;
  color: #eee;
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
}

.asmBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.asmBlock.highlight {
  background: #5b6d71;
}

.asmLine {
  white-space: pre-line;
}

.asmLine .red {
  color: #f25975;
}

.asmLine .blue {
  color: #1bcee6;
}

.asmLine .purple {
  color: #bc89e8;
}

.asmLine .grey {
  color: #aaa;
}

.dropdownOuter {
  position: relative;
  background: none;
  font: inherit;
  border: none;
}

.dropdownOuter.open {
  background: #6d6b94;
  border-radius: 0;
}

.dropdownInner {
  position: absolute;
  top: 100%;
  left: 0;
  background: #4f4d6f;
  width: 250px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  display: none;
}

.visible {
  display: flex;
}

.dropdownItem {
  display: flex;
  padding: 12px;
  cursor: pointer;
  background: #6d6b94;
  border: none;
  font: inherit;
  color: #fff;
}

.dropdownItem:hover {
  background: #807db3;
}
